import { NextRouter } from 'next/router';

type routerQueryType = {
    pageUrl?: string | string[];
};

type routerPathType = string | string[] | null;

export const addParamToUrl = (
    router: NextRouter,
    param: string,
    value: string,
    isSlider: boolean,
    replace?: boolean
) => {
    const routerQuery: routerQueryType = router.query;
    const routerPath: routerPathType =
        routerQuery && routerQuery.pageUrl && Array.isArray(routerQuery.pageUrl)
            ? routerQuery.pageUrl.join('/')
            : null;
    const filteredByKey = Object.fromEntries(
        Object.entries(routerQuery).filter(
            ([key]) => key !== 'pageUrl' && key !== 'page'
        )
    );
    const objectParam: any = {};
    const existingParam = Array.isArray(filteredByKey[param])
        ? filteredByKey[param]
        : filteredByKey[param]
        ? [filteredByKey[param]]
        : null;

    if (Array.isArray(existingParam) && !replace) {
        existingParam.push(value);
        isSlider
            ? (objectParam['slider_' + param] = existingParam)
            : (objectParam[param] = existingParam);
    } else {
        isSlider
            ? (objectParam['slider_' + param] = value)
            : (objectParam[param] = value);
    }
    router.push(
        {
            pathname: routerPath,
            query: { ...filteredByKey, ...objectParam }
        },
        undefined,
        { shallow: true }
    );
};

export const removeParamFromUrl = (
    router: NextRouter,
    param: string,
    value: string
) => {
    const routerQuery: routerQueryType = router.query;
    const routerPath: routerPathType =
        routerQuery && routerQuery.pageUrl && Array.isArray(routerQuery.pageUrl)
            ? routerQuery.pageUrl.join('/')
            : null;
    const filteredByKey = Object.fromEntries(
        Object.entries(routerQuery).filter(
            ([key]) => key !== 'pageUrl' && key !== 'page'
        )
    );
    const objectParam: any = {};
    let existingParam = Array.isArray(filteredByKey[param])
        ? filteredByKey[param]
        : filteredByKey[param]
        ? [filteredByKey[param]]
        : null;

    if (Array.isArray(existingParam)) {
        existingParam = existingParam.filter((f) => f !== value);
        objectParam[param] = existingParam;
    }

    if (Array.isArray(existingParam)) {
        router.push(
            {
                pathname: routerPath,
                query: { ...filteredByKey, ...objectParam }
            },
            undefined,
            { shallow: true }
        );
    }
};

export const checkParamExists = (
    router: NextRouter,
    param: string,
    value: string
) => {
    const routerQuery: routerQueryType = router.query;
    const filteredByKey = Object.fromEntries(
        Object.entries(routerQuery).filter(
            ([key, val]) => key === param && val.includes(value)
        )
    );
    return Object.keys(filteredByKey).length === 0 &&
        filteredByKey.constructor === Object
        ? false
        : true;
};

export const convertFiltersToObjForGraphql = (
    router: NextRouter,
    filters?: object
) => {
    const routerQuery: routerQueryType = router.query;
    const filteredByKey = Object.fromEntries(Object.entries(routerQuery));
    const resultContainer: any = {};
    Object.keys(filteredByKey).map((key) => {
        const handledKey = key.startsWith('slider_')
            ? key.replace('slider_', '')
            : key;
        if (key.startsWith('slider_')) {
            const start = String(filteredByKey[key]).split('_')[0];
            const end = String(filteredByKey[key]).split('_')[1];
            resultContainer[handledKey] = { min: start, max: end };
        } else {
            if (Array.isArray(filteredByKey[key])) {
                resultContainer[key] = filteredByKey[key];
            } else {
                resultContainer[key] = [filteredByKey[key]];
            }
        }
    });

    return { ...filters, ...resultContainer };
};

export const returnSortType = (router: NextRouter, isSearchSort?: boolean) => {
    const selectedSort: string | string[] | undefined = router.query.sort;
    let handedSortFromUrl: any = {};
    const destructedString = selectedSort
        ? typeof selectedSort === 'string'
            ? selectedSort.split(' ')
            : null
        : null;
    destructedString
        ? isSearchSort
            ? (handedSortFromUrl =
                  destructedString[0] + ' ' + destructedString[1])
            : (handedSortFromUrl[destructedString[0]] = destructedString[1])
        : null;
    const handledSort = handedSortFromUrl;
    return Object.keys(handledSort).length === 0 &&
        handledSort.constructor === Object
        ? null
        : handledSort;
};

export const returnSelectedPage = (router: NextRouter) => {
    const selectedPage: string | string[] | undefined = router.query.page;
    return selectedPage;
};

export const clearAllParams = (
    router: NextRouter,
    isSearchFilter?: boolean
) => {
    const searchQuery = isSearchFilter
        ? 'suche?query=' + router.query.query
        : '';
    const baseUrl = Array.isArray(router.query.pageUrl)
        ? router.query.pageUrl.join('/') + searchQuery
        : '/' + searchQuery;
    router.replace(baseUrl, undefined, { shallow: true });
};
