import React from 'react';
import { addParamToUrl } from '../../util/urlManipulatingFunctions';
import { useRouter } from 'next/router';
import Loading from './images/loading.svg';
import ArrowNextPage from './images/arrowNextPage.svg';
import ArrowPrevPage from './images/arrowPrevPage.svg';
import Button from '../../components/Button';
import ArrowSkipNextPage from './images/arrowSkipNextPage.svg';
import ArrowSkipPrevPage from './images/arrowSkipPrevPage.svg';

interface PaginationProps {
    pageInfo: {
        total_pages: number;
        current_page: number;
    };
    fetchMore?: Function;
    loading: boolean;
    fetchMoreMode: boolean;
    scrollRef?: React.RefObject<HTMLElement>;
    mobileScrollRef?: React.RefObject<HTMLElement>;
}

const Pagination = (props: PaginationProps) => {
    const {
        pageInfo,
        fetchMore,
        loading,
        fetchMoreMode,
        scrollRef,
        mobileScrollRef
    } = props;
    const { total_pages, current_page } = pageInfo;

    // const total_pages = 6;
    // const current_page = 3;
    const nextPage =
        current_page >= total_pages ? total_pages : current_page + 1;
    const prevPage = current_page <= 1 ? 1 : current_page - 1;
    const router = useRouter();
    const maxPageToShow = total_pages >= 5 ? 5 : total_pages;
    const pages: number[] = [];
    const handledPages: number[] = [];
    for (let index: number = 1; index <= total_pages; index++) {
        pages.push(index);
    }

    const isSmallSize = window.innerWidth < 425;

    if (current_page > 2) {
        if (current_page + (isSmallSize ? 1 : 2) >= pages.length) {
            for (
                let index: number =
                    pages.length - (isSmallSize ? 3 : maxPageToShow) + 1;
                index < pages.length + 1;
                index++
            ) {
                handledPages.push(index);
            }
        } else {
            !isSmallSize && handledPages.push(pages[current_page - 3]);
            handledPages.push(pages[current_page - 2]);
            handledPages.push(pages[current_page - 1]);
            handledPages.push(pages[current_page]);
            !isSmallSize && handledPages.push(pages[current_page + 1]);
        }
    } else {
        for (
            let index: number = 1;
            index <= (isSmallSize ? 3 : maxPageToShow);
            index++
        ) {
            handledPages.push(index);
        }
    }

    const handlePageChange = (page: string) => {
        addParamToUrl(router, 'page', page, false, true);
        if (window.innerWidth <= 1024 && mobileScrollRef) {
            mobileScrollRef.current?.scrollIntoView();
        } else {
            scrollRef?.current?.scrollIntoView();
        }
    };

    const handleNextPage = () => {
        addParamToUrl(router, 'page', String(nextPage), false, true);
        if (window.innerWidth <= 1024 && mobileScrollRef) {
            mobileScrollRef.current?.scrollIntoView();
        } else {
            scrollRef?.current?.scrollIntoView();
        }
    };

    const handlePrevPage = () => {
        addParamToUrl(router, 'page', String(prevPage), false, true);
        if (window.innerWidth <= 1024 && mobileScrollRef) {
            mobileScrollRef.current?.scrollIntoView();
        } else {
            scrollRef?.current?.scrollIntoView();
        }
    };

    const handleSkipNextPage = () => {
        addParamToUrl(
            router,
            'page',
            total_pages - current_page >= 5
                ? String(current_page + 5)
                : String(total_pages),
            false,
            true
        );

        if (window.innerWidth <= 1024 && mobileScrollRef) {
            mobileScrollRef.current?.scrollIntoView();
        } else {
            scrollRef?.current?.scrollIntoView();
        }
    };

    const handleSkipPrevPage = () => {
        addParamToUrl(
            router,
            'page',
            current_page > 5 ? String(current_page - 5) : '1',
            false,
            true
        );

        if (window.innerWidth <= 1024 && mobileScrollRef) {
            mobileScrollRef.current?.scrollIntoView();
        } else {
            scrollRef?.current?.scrollIntoView();
        }
    };

    return (
        <div>
            {fetchMoreMode ? (
                fetchMore ? (
                    <div className="w-full flex justify-center">
                        <Button
                            onClick={() => {
                                fetchMore();
                            }}
                            disabled={loading}
                            className="my-6 text-lg"
                        >
                            {loading ? <Loading /> : 'Mehr Laden'}
                        </Button>
                    </div>
                ) : null
            ) : total_pages > 1 ? (
                <div className="flex flex-col items-center my-12">
                    <div className="flex text-gray-700 space-x-2">
                        <button
                            onClick={handleSkipPrevPage}
                            type="button"
                            className="h-8 w-8 flex justify-center items-center rounded-full bg-skin-site-secondary-bg cursor-pointer hover:bg-skin-red-bg group hover:text-white disabled:opacity-50 disabled:hover:bg-skin-site-secondary-bg disabled:hover:fill-gray-700 disabled:cursor-auto xs:w-9 xs:h-9"
                            disabled={
                                current_page == 1 || loading ? true : false
                            }
                        >
                            <ArrowSkipPrevPage className="group-hover:fill-white group-disabled:fill-black" />
                        </button>
                        <button
                            onClick={handlePrevPage}
                            type="button"
                            className="h-8 w-8 flex justify-center items-center rounded-full bg-skin-site-secondary-bg cursor-pointer transition duration-150 hover:bg-skin-red-bg hover:text-white disabled:opacity-50 disabled:hover:bg-skin-site-secondary-bg disabled:hover:text-gray-700 disabled:cursor-auto xs:w-9 xs:h-9"
                            disabled={
                                current_page == 1 || loading ? true : false
                            }
                        >
                            <ArrowPrevPage />
                        </button>
                        {handledPages &&
                            handledPages.map((page) => (
                                <button
                                    key={page}
                                    type="button"
                                    onClick={() =>
                                        handlePageChange(String(page))
                                    }
                                    className="flex h-8 font-medium rounded-full bg-skin-site-secondary-bg xs:h-9"
                                    disabled={page === current_page || loading}
                                >
                                    <div
                                        className={
                                            page === current_page
                                                ? 'w-8 h-full flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-skin-red-bg text-white xs:w-9'
                                                : 'w-8 h-full flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in  rounded-full hover:bg-skin-red-bg hover:text-white xs:w-9'
                                        }
                                    >
                                        {page}
                                    </div>
                                </button>
                            ))}
                        <button
                            onClick={handleNextPage}
                            type="button"
                            className="h-8 w-8 flex justify-center items-center rounded-full bg-skin-site-secondary-bg cursor-pointer transition duration-150 hover:bg-skin-red-bg hover:text-white disabled:opacity-50 disabled:hover:bg-skin-site-secondary-bg disabled:hover:text-gray-700 disabled:cursor-auto xs:w-9 xs:h-9"
                            disabled={
                                current_page == total_pages || loading
                                    ? true
                                    : false
                            }
                        >
                            <ArrowNextPage />
                        </button>
                        <button
                            onClick={handleSkipNextPage}
                            type="button"
                            className="h-8 w-8 flex justify-center items-center rounded-full bg-skin-site-secondary-bg cursor-pointer hover:bg-skin-red-bg group hover:text-white disabled:opacity-50 disabled:hover:bg-skin-site-secondary-bg disabled:hover:fill-gray-700 disabled:cursor-auto xs:w-9 xs:h-9"
                            disabled={
                                current_page == total_pages || loading
                                    ? true
                                    : false
                            }
                        >
                            <ArrowSkipNextPage className="group-hover:fill-white group-disabled:fill-black" />
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Pagination;
